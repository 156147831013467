import { add, remove, collections } from 'OK/LinkedHooksStore';

var collectionName = collections.ICON_REACTIONS;

export default class ReactIconsWithCounter {
    activate(el) {
        this.el = el;
        this.hookId = el.getAttribute('data-req');
        this.refId = el.getAttribute('data-likeref');

        add(this.refId, collectionName, this.hookId);
    }

    deactivate() {
        remove(this.refId, collectionName, this.hookId);
    }
}
